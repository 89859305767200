/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import AgeRangeIcon from '../../../assets/img/ico-age-range.svg'
import NotificationPrimaryIcon from '../../../assets/img/ico-notification-1.svg?react'
import NotificationSecondaryIcon from '../../../assets/img/ico-notification-2.svg?react'
import Button from '../../../compoments/Button'
import Modal from '../../../compoments/Modal'
import { useGetMeQuery } from '../../../services/auth'
import { useAddUserMutation } from '../../../services/notifications'
import type { IOffender } from '../../../types'

interface OffenderPopupProps {
  data: IOffender
  isOpen: boolean
  onClose: () => void
}

const OffenderPopup = ({ data, isOpen, onClose }: OffenderPopupProps) => {
  const { data: userData, refetch } = useGetMeQuery()
  const [triggerAddUser] = useAddUserMutation()

  const [isNotify, setIsNotify] = useState(false)
  const selectedOffenders = userData?.data?.offenders || []

  useEffect(() => {
    if (selectedOffenders.includes(data?.id as string)) {
      setIsNotify(true)
    }
  }, [selectedOffenders])

  const handleChange = async () => {
    if (!data?.id) return

    try {
      setIsNotify(true)
      const res = await triggerAddUser({ offenderDocIds: [data.id] }).unwrap()

      if (res.success) {
        toast.success('User added successfully')
        await refetch()
      } else {
        setIsNotify(false)
        toast.error('User not added successfully')
      }
    } catch (e) {
      setIsNotify(false)
      toast.error('Please Login / Sign Up')
    }
  }

  const address = data?.address[0]

  if (!data) return null
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="modal__body">
        <div className="card card-custom-1 h-full">
          <div className="wrapper-image card-bg--image">
            <img
              src={`${import.meta.env.VITE_API_URL + '/api/radar-service/files/' + data.image_src}`}
              onError={(e) => {
                e.currentTarget.src = AgeRangeIcon
              }}
              alt="card-photo"
            />
            <div className="card-actions">
              <label className="btn">
                <input
                  disabled={isNotify}
                  type="checkbox"
                  hidden
                  checked={isNotify}
                  onChange={handleChange}
                />
                <div className="btn btn--square btn--md rounded-full btn-check--likely">
                  <span className="ico">
                    <NotificationPrimaryIcon />
                  </span>
                  <span className="ico">
                    <NotificationSecondaryIcon />
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div className="card-body">
            <div className="heading-block">
              <span className="text--lg heading">{data.offenderName}</span>
              <p className="default-text text--sm heading-desk">
                {address?.street}
              </p>
              <div className="flex flex-col gap--md mt-3">
                <div className="card-details">
                  <label className="form-label">
                    <div className="label--text">
                      <span>Age</span>
                    </div>
                  </label>
                  <p className="card-details__body">
                    {data.age || 'Not Specified'}
                  </p>
                </div>
                <div className="card-details">
                  <label className="form-label">
                    <div className="label--text">
                      <span>Addresses</span>
                    </div>
                  </label>
                  <p className="card-details__body">
                    {data.address?.length
                      ? data.address.map(({ street }) => street)
                      : 'Not Specified'}
                  </p>
                </div>
                <div className="card-details">
                  <label className="form-label">
                    <div className="label--text">
                      <span>Aliases</span>
                    </div>
                  </label>
                  <p className="card-details__body">
                    {data.aliases || 'Not Specified'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal__footer">
        <Button onClick={onClose}>
          <span>Close</span>
        </Button>
      </div>
    </Modal>
  )
}

export default OffenderPopup
