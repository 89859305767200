/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import AgeRangeIcon from '../../../assets/img/ico-age-range.svg'
import NotificationPrimaryIcon from '../../../assets/img/ico-notification-1.svg?react'
import NotificationSecondaryIcon from '../../../assets/img/ico-notification-2.svg?react'
import { useGetMeQuery } from '../../../services/auth'
import { useAddUserMutation } from '../../../services/notifications'
import type { IOffender } from '../../../types'

const CardPerson = ({
  data,
  selectedOffenders,
}: {
  data?: IOffender
  selectedOffenders: string[]
}) => {
  const [isNotify, setIsNotify] = useState(false)

  const [triggerAddUser] = useAddUserMutation()
  const { refetch } = useGetMeQuery()

  useEffect(() => {
    if (selectedOffenders.includes(data?.id as string)) {
      setIsNotify(true)
    }
  }, [selectedOffenders])

  const handleChange = async () => {
    if (!data?.id) return

    try {
      setIsNotify(true)
      const res = await triggerAddUser({ offenderDocIds: [data.id] }).unwrap()

      if (res.success) {
        // toast.success('Offender Added Successfully')
        await refetch()
      } else {
        setIsNotify(false)
        toast.error('User not added successfully')
      }
    } catch (e) {
      setIsNotify(false)
      toast.error('Please Login / Sign Up')
    }
  }

  const address = data?.address[0]

  if (!data) return null

  return (
    <div className="card card-custom-1 h-full">
      <div className="wrapper-image card-bg--image">
        <img
          src={`${import.meta.env.VITE_API_URL + '/api/radar-service/files/' + data.image_src}`}
          onError={(e) => {
            e.currentTarget.src = AgeRangeIcon
          }}
          alt="card-photo"
        />
        <div className="card-actions">
          <label className="btn" onClick={(e) => e.stopPropagation()}>
            <input
              disabled={isNotify}
              type="checkbox"
              hidden
              checked={isNotify}
              onChange={handleChange}
            />
            <div className="btn btn--square btn--md rounded-full btn-check--likely">
              <span className="ico">
                <NotificationPrimaryIcon />
              </span>
              <span className="ico">
                <NotificationSecondaryIcon />
              </span>
            </div>
          </label>
        </div>
      </div>
      <div className="card-body card-person">
        <div className="heading-block">
          <span className="text--lg heading">{data.offenderName}</span>
          <p className="default-text text--sm heading-desk">
            {address?.street}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CardPerson
