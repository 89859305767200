import { createSlice } from '@reduxjs/toolkit'

import { authApi } from '../../services/auth'

interface AuthState {}
const initialState = {} satisfies AuthState as AuthState

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.getMe.matchRejected,
      (state, { payload }) => {
        window.localStorage.removeItem('authToken')
      },
    )
  },
})

// export const {} = alertsSlice.actions

export default alertsSlice.reducer
