import React from 'react'
import { Provider as ReduxStoreProvider } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { HistoryRouter } from 'redux-first-history/rr6'

import FormLayout from './layouts/FormLayout'
import MainLayout from './layouts/MainLayout'
import AlertsPage from './pages/AlertsPage'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import LoginPhone from './pages/LoginPage/LoginPhone'
import NotFound from './pages/NotFoundPage'
import NotificationsPage from './pages/NotificationsPage'
import { Settings } from './pages/Settings/Settings'
import SignUpPage from './pages/SignUpPage'
import { history, store } from './store'

import './assets/css/custom.css'
import 'react-toastify/dist/ReactToastify.css'
import { ServiceComponent } from './ServiceComponent'
import OAuth from './pages/OAuth'

const App: React.FC = () => {
  return (
    <ReduxStoreProvider store={store}>
      <HistoryRouter history={history}>
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/alerts" element={<AlertsPage />} />
            <Route path="/notifications" element={<NotificationsPage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route element={<FormLayout />}>
            <Route path="/create-account" element={<SignUpPage />} />
            <Route path="/login-email" element={<LoginPage />} />
            <Route path="/login-phone" element={<LoginPhone />} />
          </Route>
          <Route path="/settings/:page?" element={<Settings />} />
          <Route path="/sign-in" element={<OAuth />} />
        </Routes>
      </HistoryRouter>
      <ToastContainer
        icon={false}
        hideProgressBar={true}
        autoClose={1000}
        theme="dark"
      />
      <ServiceComponent />
    </ReduxStoreProvider>
  )
}

export default App
