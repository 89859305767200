import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AgeRangeIcon from '../../../assets/img/ico-age-range.svg?react'
import UserAvatar from '../../../assets/img/user-ava-98x98.png'

type FuncProps = {
  onChange?: (file: File | null) => void
  src?: string
}

export const ChangePhoto: React.FC<FuncProps> = ({ onChange, src }) => {
  const { t } = useTranslation('settings')
  const [file, setFile] = useState<File | null>(null)

  const changePhoto = (selectedFile: File) => {
    setFile(selectedFile)
    if (onChange) {
      onChange(selectedFile)
    }
  }

  useEffect(() => {
    return () => {
      if (file) {
        URL.revokeObjectURL(URL.createObjectURL(file))
      }
    }
  }, [file])

  return (
    <div className="foto-load-form w-full gap-16">
      <div className="foto-load">
        {file || src ? (
          <img
            src={
              file
                ? URL.createObjectURL(file)
                : import.meta.env.VITE_API_URL + src
            }
          />
        ) : (
          // <div className="user-avatar-sub"></div>
          <AgeRangeIcon />
        )}
      </div>

      <label className="btn btn--primary btn--sm radius-full pointer">
        <input
          type="file"
          className="hidden"
          onChange={(e) => {
            const selectedFile = e.target.files && e.target.files[0]
            if (selectedFile) {
              changePhoto(selectedFile)
            }
          }}
        />
        <span>{t('profile.change_photo')}</span>
      </label>
    </div>
  )
}
