import type { SliderProps } from 'rc-slider'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import React, { useEffect, useRef, useState } from 'react'

interface TooltipSliderProps extends SliderProps {
  tipFormatter?: (value: number) => React.ReactNode
  tipProps?: any
}

const TooltipSlider: React.FC<TooltipSliderProps> = ({
  tipFormatter,
  tipProps,
  ...props
}) => {
  const tipRef = useRef<HTMLDivElement>(null)
  const tipHandleRender: SliderProps['handleRender'] = (node, handleProps) => {
    const tooltipContent = tipFormatter
      ? tipFormatter(handleProps.value as number)
      : handleProps.value
    const handlePosition = parseInt(node.props.style?.left as string, 10)
    return (
      <div style={{ position: 'absolute', ...node.props.style }}>
        {node}
        {/* <div
          ref={tipRef}
          className="custom-tooltip"
          style={{
            position: 'absolute',
            whiteSpace: 'nowrap',
            left: handlePosition > 50 ? 'auto' : 0,
            right: handlePosition < 50 ? 'auto' : 0,
            marginTop: 16,
            ...tipProps,
          }}
        >
          {tooltipContent}
        </div> */}
      </div>
    )
  }

  return <Slider {...props} handleRender={tipHandleRender} />
}

interface InputRangeProps {
  values?: number[]
  label?: string
  onChange?: (value: number[]) => void
  onChangeComplete?: (value: number[]) => void
  min?: number
  max?: number
  step?: number
  tipFormatter: (value: number) => string
}

const InputRange: React.FC<InputRangeProps> = ({
  values = [0, 10],
  onChange = () => {},
  onChangeComplete = () => {},
  min = 0,
  max = 100,
  step = 1,
  tipFormatter,
}) => {
  const [range, setRange] = useState<number[]>(values)
  const handleChange = (value: number | number[]) => {
    const newValue = Array.isArray(value) ? (value as number[]) : [value, value]
    setRange(newValue)
    if (onChange) {
      onChange(newValue)
    }
  }
  const handleChangeComplete = (value: number | number[]) => {
    const newValue = Array.isArray(value) ? (value as number[]) : [value, value]
    if (onChangeComplete) {
      onChangeComplete(newValue)
    }
  }

  useEffect(() => {
    setRange(values)
  }, [values])

  return (
    <div className="form-group select--outline select--outline-bg select--outline-2 input--sm ui-slider-wrapper flex-auto">
      <div className="widget-range noUISlider-Class --secondary w-full">
        <TooltipSlider
          min={min}
          max={max}
          step={step}
          value={range}
          onChange={handleChange}
          onChangeComplete={handleChangeComplete}
          tipFormatter={tipFormatter}
          tipProps={{
            placement: 'bottom',
            overlayInnerStyle: {},
          }}
          range
        />
        <div className="flex items-center justify-between mt-4">
          {range.map((item) => (
            <div
              className="custom-tooltip"
              // style={{
              //   position: 'absolute',
              //   whiteSpace: 'nowrap',
              //   left: isLeft ? 'auto' : 0,
              //   right: isLeft ? 0 : 'auto',
              //   marginTop: 16,
              //   ...tipProps,
              // }}
            >
              {tipFormatter ? tipFormatter(item) : item}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default InputRange
