import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { getCountries, getCountryCallingCode } from 'libphonenumber-js'
import { useRef, useState } from 'react'
import type { MouseEventHandler, FC } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { InferType } from 'yup'
import { object, string } from 'yup'

import Button from '../../compoments/Button'
import FormHeader from '../../compoments/FormHeader'
import Input from '../../compoments/Input'
import SelectBox from '../../compoments/SelectBox'
import SocialButtons from '../../compoments/SocialButtons'
import { useLoginPhoneMutation } from '../../services/auth'

const schema = object({
  // phone: string()
  //   .required('Phone is requiered')
  //   .test({
  //     name: 'phone',
  //     message: 'Incorrect phone format',
  //     test: (value) => {
  //       return isValidPhoneNumber(value)
  //     },
  //   }),
  phone: string().required('Phone is requiered'),
})
type FormData = InferType<typeof schema>

const LoginPhone: FC = () => {
  const [login, { data, isLoading }] = useLoginPhoneMutation()

  const contryCodesOptions = getCountries().map((country) => ({
    label: `${country} +${getCountryCallingCode(country)}`,
    value: country,
  }))
  const [codeValue, setCodeValue] = useState('')
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  })
  const onSubmit = async (data: FormData) => {
    console.log('Form data submitted:', data)
    try {
      const resp = await login({
        phone: data.phone,
        phoneCountry: codeValue,
      }).unwrap()
      console.log('fulfilled', resp)
      if (!resp.success) {
        toast.error(resp.message)
        return
      }
      setStep(2)
    } catch (error) {
      console.error('rejected', error)
    }
  }
  const [step, setStep] = useState(1)
  const [code, setCode] = useState<string[]>(Array(6).fill(''))
  const handleChange = (value: string, index: number) => {
    const newValue = value.slice(0, 1)
    const newCode = [...code]
    newCode[index] = newValue

    setCode(newCode)
    console.log(inputsRef.current[index + 1])
    // Move focus to the next input
    if (value && index < 6 - 1) {
      inputsRef.current[index + 1]?.focus()
    }

    // Check if the code is complete
    if (newCode.every((num) => num !== '')) {
      console.log(newCode.join(''))
    }
  }
  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputsRef.current[index - 1]?.focus()
    }
  }
  const navigate = useNavigate()
  const inputsRef = useRef<Array<HTMLInputElement | null>>([])
  const [codeErrors, setCodeErrors] = useState<boolean[]>(Array(6).fill(false))
  const handleSubmitCode: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()
    setCodeErrors(Array(6).fill(false))
    let hasErrors = false
    setCodeErrors(
      code.map((value) => {
        if (!value) hasErrors = true
        return !value
      }),
    )
    if (hasErrors) return
    try {
      const resp = await login({
        phone: codeValue + getValues('phone'),
        code: Number(code.join('')),
      }).unwrap()
      console.log('fulfilled', resp)
      if (!resp.success) {
        toast.error(resp.message)
        return
      }
      // setStep(2)
      window.localStorage.setItem('authToken', resp.data.token)
      // toast.success(resp.message)
      navigate('/')
    } catch (error) {
      console.error('rejected', error)
    }
  }
  const [resendText, setResendText] = useState('Resend')
  const handleResend: MouseEventHandler<HTMLButtonElement> = async (e) => {
    try {
      // const resp = await recovery(email).unwrap()
      console.log('fulfilled', resp)
      if (!resp.success) {
        toast.error(resp.message)
        return
      }
      setResendText('Code sent!')
      setTimeout(() => {
        setResendText('Resend')
      }, 2000)
      // if (resp.success && resp.data) {
      //   window.localStorage.setItem('authToken', resp.data.token)
      //   toast.success('Login successfully!')
      //   navigate('/')
      // } else {
      //   toast.error(resp?.message)
      // }
    } catch (error) {
      console.error('rejected', error)
    }
  }
  return step === 1 ? (
    <>
      <FormHeader
        hasLogo
        heading="Login"
        description="Welcome back, fill in form to login account"
      />
      <div className="form-body">
        <div className="row">
          <div className="col-md-12 col-12 mb-28">
            <div className="row">
              <div className="col-4">
                <div className="form-group select--outline input--lg">
                  <label className="form-label">
                    <div className="label--text">
                      <span>Country</span>
                    </div>
                  </label>
                  <SelectBox
                    wrapperClassName={
                      'select--outline input--sm standard-height'
                    }
                    options={contryCodesOptions}
                    defaultValue={{
                      label: 'US +1',
                      value: '+1',
                    }}
                    selectValue={codeValue}
                    onChange={(option) => setCodeValue(option?.value)}
                  />
                </div>
              </div>
              <div className="col-8">
                <Input
                  label="Phone number"
                  // inputStyle={InputStyles.outline}
                  placeholder="Enter your phone..."
                  error={errors.phone?.message}
                  {...register('phone')}
                />
              </div>
            </div>
          </div>
          <div className="col-12 mb-44">
            <Button
              type="button"
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            >
              Login
            </Button>
          </div>
        </div>
      </div>

      <div className="form-footer">
        <div className="row">
          <div className="col-12 mb-28">
            <SocialButtons button="email" />
          </div>
          <div className="col-12">
            <div className="flex items-center justify-center gap-10">
              <span className="text--md">Don’t have an account?</span>
              <Link
                to="/create-account"
                className="color-secondary text--md fw-600"
              >
                Create an Account
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <FormHeader
        hasArrow
        handleBack={() => setStep(1)}
        heading="Enter your code"
        description={
          <span>
            We sent a code to <br />{' '}
            <a
              className="color-secondary fw-600 lh-26"
              href={`tel:${codeValue + getValues('phone')}`}
            >
              {codeValue + getValues('phone')}
            </a>
          </span>
        }
      />
      <div className="form-body">
        <div className="row">
          <div className="code w-full mx-auto" id="code-input">
            {code.map((digit, index) => (
              <div className="code-col">
                <label className="code-item">
                  <input
                    type="number"
                    inputMode="numeric"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    // autoFocus={index === currentInputIndex}
                    ref={(el) => (inputsRef.current[index] = el)}
                    className={clsx(digit && 'active-value')}
                  />

                  <div
                    className="code-block"
                    style={{
                      borderColor: codeErrors[index]
                        ? 'var(--danger)'
                        : '#E2E2E2',
                    }}
                  ></div>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="form-footer">
        <div className="row">
          <div className="col-12">
            <Button
              type="submit"
              onClick={handleSubmitCode}
              className="mb-28"
              isLoading={isLoading}
            >
              Submit
            </Button>
            <div className="flex items-center justify-center gap-10">
              <span className="text--md">Don’t receive the code?</span>
              <button
                disabled={isLoading || resendText === 'Code sent!'}
                className="color-secondary text--md fw-600"
                onClick={handleResend}
              >
                {isLoading ? 'Sending...' : resendText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default LoginPhone
