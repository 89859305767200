import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { InferType } from 'yup'
import { object, string } from 'yup'

import type { StepsProps } from '..'
import Button from '../../../compoments/Button'
import Checkbox, { CheckboxSizes } from '../../../compoments/Checkbox'
import FormHeader from '../../../compoments/FormHeader'
import Input from '../../../compoments/Input'
import PasswordInput from '../../../compoments/PasswordInput'
import type { FormContextValue } from '../../../layouts/FormLayout'
import { useRequestRecoveryMutation } from '../../../services/auth'

import Step3 from './Step3'
import Step4 from './Step4'

const stepTexts = {
  1: {
    heading: 'Reset password',
    description: 'No worries, we’ll send you instructions.',
  },
  2: {
    heading: 'Enter your code',
    description: (
      <span>
        We sent a code to <br />{' '}
        <a
          className="color-secondary fw-600 lh-26"
          href="mailto:jackson.graham@example.com"
        >
          jackson.graham@example.com
        </a>
      </span>
    ),
  },
  3: {
    heading: 'Set new password',
    description: 'Must be at least 8 characters',
  },
}

const schema = object({
  email: string().email('Invalid email format').required('Email is required'),
})
type FormType = InferType<typeof schema>

const Step2: React.FC<StepsProps> = ({ setStep }) => {
  const [resetStep, setResetStep] = useState<number>(1)
  console.log(resetStep)
  // const { formHeight, setFormHeight } = useOutletContext<FormContextValue>()
  // useEffect(() => {
  //   setFormHeight('min-h-500')
  // })
  const inputRef = useCallback((code: HTMLDivElement | null) => {
    if (code !== null) {
      const input_childs = code.querySelectorAll<HTMLInputElement>(
        "input[type='number']",
      )

      if (input_childs.length > 0) {
        const first_input = input_childs[0]
        const form_closest = first_input.closest('form')

        const get_notfocus_inputs = function () {
          const not_focus_inputs_all = code.querySelectorAll<HTMLInputElement>(
            'input:not([autofocus])',
          )
          for (let i = 0; i < not_focus_inputs_all.length; i++) {
            not_focus_inputs_all[i].disabled = true
          }
        }

        if (first_input.getAttribute('autofocus') !== null) {
          get_notfocus_inputs()
        } else {
          first_input.setAttribute('autofocus', 'true')
          get_notfocus_inputs()
        }

        // Function to clear all inputs
        clearAllInputsRef.current = () => {
          input_childs.forEach((input) => {
            input.value = ''
            input.classList.remove('active-value')
            input.disabled = false
          })
          first_input.focus() // Set focus back to the first input
        }

        getCodeRef.current = () => {
          const codeValues = Array.from(input_childs).map(
            (input) => input.value,
          )
          return codeValues.join('') // Join as a single string (or keep as array if preferred)
        }

        for (let i = 0; i < input_childs.length; i++) {
          const inp = input_childs[i]
          inp.addEventListener('input', function (ev) {
            const target = ev?.target as HTMLInputElement
            if (parseInt(target?.value, 10) >= 0) {
              target.classList.add('active-value')
            } else {
              target.classList.remove('active-value')
            }

            const val_inp = target.value
            if (val_inp !== '') {
              inp.disabled = true
              const next_inp = input_childs[i + 1]
              if (next_inp) {
                next_inp.disabled = false
                next_inp.focus()
              } else {
                if (form_closest) {
                  const submit_form =
                    form_closest.querySelector<HTMLButtonElement>(
                      "[type='submit']",
                    )
                  // Optional form submission
                  // submit_form?.click();
                }
              }
            }
          })

          document.addEventListener('keydown', function (ev) {
            ev.stopPropagation()
            if (ev.key === 'e') {
              inp.value = ''
            }
          })

          document.addEventListener('keyup', function (ev) {
            ev.stopPropagation()
            if (ev.key === 'e') {
              inp.value = ''
            }
          })
        }

        // Expose these functions if needed, or bind to buttons for clear or get code actions
        // Example:
        // clearAllInputs();
        // console.log(getCode());
      }
    }
  }, [])
  const [recovery, recoveryOptions] = useRequestRecoveryMutation()
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormType>({
    resolver: yupResolver(schema),
  })
  const onSubmit = async (data: FormType) => {
    try {
      const resp = await recovery(data.email).unwrap()
      console.log('fulfilled', resp)
      if (!resp.success) {
        toast.error(resp.message)
        return
      }
      setResetStep(2)
      // if (resp.success && resp.data) {
      //   window.localStorage.setItem('authToken', resp.data.token)
      //   toast.success('Login successfully!')
      //   navigate('/')
      // } else {
      //   toast.error(resp?.message)
      // }
    } catch (error) {
      console.error('rejected', error)
    }
  }
  const [code, setCode] = useState<number | null>(null)
  console.log(code, resetStep)
  return (
    <>
      {resetStep === 1 && (
        <>
          <FormHeader
            hasArrow
            handleBack={() => setStep(1)}
            heading={stepTexts[resetStep as keyof typeof stepTexts].heading}
            description={
              stepTexts[resetStep as keyof typeof stepTexts].description
            }
          />
          <div className="form-body">
            <div className="row">
              {resetStep === 1 && (
                <div className="col-md-12 col-12 mb-22">
                  <Input
                    label="Email"
                    placeholder="Enter your email"
                    error={errors.email?.message}
                    {...register('email')}
                  />
                </div>
              )}
              {resetStep === 2 && (
                <div
                  className="code  w-full mx-auto "
                  ref={inputRef}
                  id="code-input"
                >
                  <div className="code-col">
                    <label className="code-item">
                      <input
                        type="number"
                        inputMode="numeric"
                        name="c0"
                        autoFocus
                      />

                      <div className="code-block"></div>
                    </label>
                  </div>
                  <div className="code-col">
                    <label className="code-item">
                      <input type="number" inputMode="numeric" name="c1" />

                      <div className="code-block"></div>
                    </label>
                  </div>
                  <div className="code-col">
                    <label className="code-item">
                      <input type="number" inputMode="numeric" name="c2" />

                      <div className="code-block"></div>
                    </label>
                  </div>
                  <div className="code-col">
                    <label className="code-item">
                      <input type="number" inputMode="numeric" name="c3" />

                      <div className="code-block"></div>
                    </label>
                  </div>
                  <div className="code-col">
                    <label className="code-item">
                      <input type="number" inputMode="numeric" name="c4" />

                      <div className="code-block"></div>
                    </label>
                  </div>
                  <div className="code-col">
                    <label className="code-item">
                      <input type="number" inputMode="numeric" name="c5" />

                      <div className="code-block"></div>
                    </label>
                  </div>
                </div>
              )}
              {resetStep === 3 && (
                <>
                  <div className="col-md-12 col-12 mb-22">
                    <PasswordInput label="Password" />
                  </div>

                  <div className="col-md-12 col-12 mb-28">
                    <PasswordInput label="Confirm password" />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="form-footer">
            <div className="row">
              {resetStep === 1 && (
                <div className="col-12">
                  <Button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={recoveryOptions.isLoading}
                  >
                    Reset Password
                  </Button>
                </div>
              )}
              {resetStep === 2 && (
                <div className="col-12">
                  <Button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault()
                      console.log('here')
                      setResetStep(3)
                    }}
                    className="mb-28"
                  >
                    Submit
                  </Button>
                  <div className="flex items-center justify-center gap-10">
                    <span className="text--md">Don’t receive the email?</span>
                    <button className="color-secondary text--md fw-600">
                      Resend
                    </button>
                  </div>
                </div>
              )}
              {resetStep === 3 && (
                <div className="col-12">
                  <Button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault()
                      setResetStep(2)
                    }}
                  >
                    Set New Password
                  </Button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {resetStep === 2 && (
        <Step3
          setStep={setStep}
          email={getValues('email')}
          setResetStep={setResetStep}
          setResetCode={setCode}
        />
      )}
      {resetStep === 3 && code && (
        <Step4 setStep={setStep} email={getValues('email')} code={code} />
      )}
    </>
  )
}
export default Step2
