import * as Yup from 'yup'

export const registerSchema = Yup.object().shape({
  fullName: Yup.string().required('Full name is required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  phoneCountry: Yup.string().required('Country is required'),
  phone: Yup.string()
    .matches(/^\+?\d+$/, 'Invalid phone number format')
    .required('Phone number is required'),
})

export const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
})

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
})

export const resetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
})

export const emailSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
})

export const phoneLoginSchema = Yup.object().shape({
  country: Yup.string().required('Country is required'),
  phoneNumber: Yup.string()
    .matches(/^\+?\d+$/, 'Invalid phone number format')
    .required('Phone number is required'),
})

export const changeProfileSchema = Yup.object().shape({
  fullName: Yup.string().required('Full name is required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  phoneCountry: Yup.string().required('Country is required'),
  phone: Yup.string()
    .matches(/^\+?\d+$/, 'Invalid phone number format')
    .required('Phone number is required'),
})

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
})
