import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const OAuth = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  useEffect(() => {
    const token = searchParams.get('authToken') || ''
    window.localStorage.setItem('token', token)
    navigate('/')
  }, [searchParams])
  return <div>OAuth</div>
}

export default OAuth
