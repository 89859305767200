/* eslint-disable jsx-a11y/label-has-associated-control */
import type { ChangeEvent, MouseEvent } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import MagnifyIcon from '../assets/img/ico-magnify.svg?react'
import NavigationIcon from '../assets/img/ico-navigation.svg?react'

import Button from './Button'

interface SearchFormProps {
  onSearch: (query: string, isNameSearch: boolean) => void
  isLoading: boolean
}

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, isLoading }) => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isNameSearch, setIsNameSearch] = useState<boolean>(true)

  const params = useLocation()
  const showSwitcher = !params.pathname.includes('settings')

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleToggle = () => {
    setIsNameSearch((prev) => !prev)
    setSearchQuery('')
  }

  const handleSearch = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onSearch(searchQuery, isNameSearch)
  }
  const { t } = useTranslation('home')

  return (
    <form className="search-form">
      {showSwitcher && (
        <label className="switch btn btn--lg">
          <input
            type="checkbox"
            className="hidden"
            checked={!isNameSearch}
            onChange={handleToggle}
          />
          <span className="switch-active">
            <span className="switch-active--bg"></span>
          </span>
          <div className="switch-controls">
            <div className="switch-control">
              <span className="switch-control--text">{t('by_name')}</span>
            </div>
            <div className="switch-control">
              <span className="switch-control--text">{t('by_city')}</span>
            </div>
          </div>
        </label>
      )}
      <div className="form-group input--lg input--icon-left input--icon-right search-input">
        <div className="input-wrapper">
          <span className="ico">
            <MagnifyIcon />
          </span>
          <input
            className="input input--solid rounded-full w-full"
            type="text"
            placeholder={t('search_placeholder')}
            value={searchQuery}
            onChange={handleInputChange}
          />
          <span className="ico">
            <NavigationIcon />
          </span>
        </div>
      </div>
      <div className="flex gap--xs items-center">
        <Button isLoading={isLoading} onClick={handleSearch}>
          {t('search')}
        </Button>
      </div>
    </form>
  )
}

export default SearchForm
