import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PlusIcon from '../../assets/img/ico-calc-plus.svg?react'
import Button from '../../compoments/Button'
import Pagination from '../../compoments/Pagination'
import { useTabs } from '../../hooks/useTabs'
import { useGetMeQuery } from '../../services/auth'
import { useDeleteZipAlertMutation } from '../../services/notifications'
import { useGetProfilesUsersMutation } from '../../services/users'

import AddAlertModal from './components/AddAlertModal'
import NameAlertsTable from './components/NameAlertsTable'
import ZipCodeAlertsTable from './components/ZipCodeAlertsTable'

const AlertsPage = () => {
  const { t } = useTranslation('alerts')
  const tabs = [
    { id: 'name_alerts', label: t('name_tab') },
    { id: 'zip_codes_alerts', label: t('zip_tab') },
  ] as const

  const { activeTab, setActiveTab } = useTabs(tabs)
  const [isAddAlertModalOpen, setIsAddAlertModalOpen] = useState(false)
  const [activeAlert, setActiveAlert] = useState(null)

  const [triggerGetUsersProfiles] = useGetProfilesUsersMutation()
  const { data: userData, refetch } = useGetMeQuery()

  console.log(userData, 'user data')

  // const nameAlerts = Array.from({ length: 12 }).map((_, index) => ({
  //   id: index,
  //   name: 'Bessie Cooper',
  //   address: '1901 Thornridge Cir. Shiloh, Hawaii',
  // }))

  const [nameAlerts, setNameAlerts] = useState<[] | any>([])

  const [zipCodes, setZipCodes] = useState<
    [] | { zip: string; range: number }[]
  >([])

  const req = async (arr: string[]) => {
    const res = await triggerGetUsersProfiles({
      offenderDocIds: arr,
    }).unwrap()

    if (res) {
      setNameAlerts(res.offenders)
    }

    console.log(res, '111111111111230812039812093123')
  }
  function getItemsForPage(items, currentPage, itemsPerPage = 10) {
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return items.slice(startIndex, endIndex)
  }
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (userData?.data?.zipcodes) {
      const arr = Object.entries(userData.data.zipcodes).map(
        ([key, value]) => ({
          zip: key as string,
          range: value as number,
        }),
      )
      setZipCodes(arr)
    }

    if (userData?.data?.offenders) {
      req(getItemsForPage(userData?.data?.offenders, currentPage))
    }
  }, [userData?.data, currentPage])

  const editAlertZip = (alert: any) => {
    setIsAddAlertModalOpen(true)
    setActiveAlert(alert)
  }
  console.log(userData?.data?.offenders?.length / 10)

  return (
    <main className="content">
      <div className="container h-full">
        <div className="catalog-page section-fullscreen headers-similars">
          <div className="catalog-page-content w-full">
            <div className="card card-border h-full">
              <div className="card-header pb-0 border-none">
                <div className="card-header--item">
                  <h1 className="heading font-500">{t('title')}</h1>
                </div>

                <div className="card-header--item">
                  {true ? (
                    <Button onClick={() => setIsAddAlertModalOpen(true)}>
                      <span className="ico">
                        <PlusIcon />
                      </span>
                      {t('add_alert')}
                    </Button>
                  ) : null}
                </div>
              </div>
              <div className="card-body overflow-hidden">
                <div className="table-block h-full">
                  <div className="table-header tabs-nav">
                    {tabs.map((tab) => (
                      <button
                        key={tab.id}
                        className={clsx('tab-link-2 btn btn--md', {
                          'active-tab': activeTab === tab.id,
                        })}
                        onClick={() => setActiveTab(tab.id)}
                      >
                        <div className="tab-link-2--content text--lg">
                          <span>{tab.label}</span>
                          <span className="count-pill">
                            {tab.id === 'zip_codes_alerts'
                              ? zipCodes.length
                              : userData?.data?.offenders?.length}
                          </span>
                        </div>
                      </button>
                    ))}
                  </div>
                  <div className="table-body overflow-hidden h-full">
                    {activeTab === 'name_alerts' && (
                      <div className="tab h-full active-tab">
                        <NameAlertsTable
                          alerts={nameAlerts}
                          onDelete={() => {}}
                        />
                      </div>
                    )}
                    {activeTab === 'zip_codes_alerts' && (
                      <div className="tab h-full active-tab">
                        <ZipCodeAlertsTable
                          alerts={zipCodes}
                          onDelete={() => {}}
                          onEdit={(alert) => editAlertZip(alert)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <Pagination
                  pageCount={
                    activeTab === 'name_alerts'
                      ? Math.ceil(userData?.data?.offenders?.length / 10) || 1
                      : 1
                  }
                  currentPage={activeTab === 'name_alerts' ? currentPage : 1}
                  onPageChange={(page) => {
                    setCurrentPage(page)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddAlertModal
        onSubmit={() => {}}
        isOpen={isAddAlertModalOpen}
        activeAlert={activeAlert}
        onClose={() => {
          setActiveAlert(null)
          setIsAddAlertModalOpen(false)
        }}
      />
    </main>
  )
}

export default AlertsPage
