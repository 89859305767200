import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import cnJSON from './locale/cn.json'
import deJSON from './locale/de.json'
import enJSON from './locale/en.json'
import esJSON from './locale/es.json'
import frJSON from './locale/fr.json'
import inJSON from './locale/in.json'
import itJSON from './locale/it.json'
import jpJSON from './locale/jp.json'
import krJSON from './locale/kr.json'
import nlJSON from './locale/nl.json'
import ptJSON from './locale/pt.json'
import saJSON from './locale/sa.json'
import trJSON from './locale/tr.json'
import uaJSON from './locale/ua.json'

i18n.use(initReactI18next).init({
  resources: {
    en: { ...enJSON },
    // pt: { ...ptJSON },
    es: { ...esJSON },
    ua: { ...uaJSON },
    fr: { ...frJSON },
    de: { ...deJSON },
    cn: { ...cnJSON },
    jp: { ...jpJSON },
    kr: { ...krJSON },
    pt: { ...ptJSON },
    it: { ...itJSON },
    sa: { ...saJSON },
    in: { ...inJSON },
    tr: { ...trJSON },
    nl: { ...nlJSON },
  },
  lng: 'en',
})
