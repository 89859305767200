import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import DownloadAppButtonGroup from '../../compoments/DownloadAppButtonGroup'

import { SettingsPath } from './settingsTypes'

export const SettingsNav: React.FC = () => {
  const { t } = useTranslation('settings')
  const tabs: { title: string; link: SettingsPath; image: string }[] = [
    {
      title: t('nav.profile'),
      link: SettingsPath.profile,
      image: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path
                      d="M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z"
                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                    <path
                      d="M6.521 10.5927L7.66568 8.59378C7.72611 8.48912 7.81306 8.40223 7.91777 8.34188C8.02247 8.28153 8.14124 8.24985 8.26209 8.25003H9.69811C9.81421 8.25022 9.92842 8.22063 10.0298 8.16409L11.086 7.58315C11.1325 7.55815 11.1755 7.52725 11.214 7.4912L13.5275 5.40034C13.6426 5.29565 13.7188 5.15501 13.7436 5.00141C13.7684 4.84781 13.7404 4.69033 13.6641 4.55472L12.7618 2.93823"
                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                    <path
                      d="M5.82474 4.57446L4.8124 6.96782C4.74402 7.12985 4.74033 7.31191 4.80209 7.47657L5.79037 10.1123C5.83098 10.22 5.89797 10.3157 5.9852 10.3908C6.07244 10.4659 6.17713 10.5179 6.28967 10.542L8.13131 10.9381C8.23345 10.96 8.32926 11.0049 8.41146 11.0694C8.49366 11.1339 8.56008 11.2163 8.60568 11.3102L8.93224 11.9866C8.98857 12.1028 9.07649 12.2007 9.18592 12.2693C9.29535 12.3378 9.42187 12.3742 9.55099 12.3742H10.7369"
                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                    <path
                      d="M18.3125 14.8225L13.8111 12.0535C13.7293 12.0036 13.638 11.9712 13.543 11.9582L11.5819 11.6935C11.4346 11.6735 11.2848 11.7019 11.155 11.7742C11.0252 11.8466 10.9223 11.959 10.8618 12.0948L9.68441 14.7365C9.62969 14.8594 9.61225 14.9957 9.63425 15.1285C9.65625 15.2612 9.71672 15.3846 9.80816 15.4833L11.5046 17.2304C11.5781 17.3095 11.632 17.4048 11.6618 17.5085C11.6917 17.6123 11.6967 17.7216 11.6764 17.8277L11.404 19.2405"
                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                </g>
                <defs>
                    <clipPath>
                        <rect width="22" height="22" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>`,
    },
    {
      title: t('nav.pass'),
      link: SettingsPath.security,
      image: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                  <g>
                      <path d="M11 8.25V11.6875" stroke="currentColor" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M7.5625 10.3125L11 11.6875" stroke="currentColor" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M8.9375 14.4375L11 11.6875" stroke="currentColor" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M13.0625 14.4375L11 11.6875" stroke="currentColor" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M14.4375 10.3125L11 11.6875" stroke="currentColor" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                        d="M18.5625 9.625V4.8125C18.5625 4.63016 18.4901 4.4553 18.3611 4.32636C18.2322 4.19743 18.0573 4.125 17.875 4.125H4.125C3.94266 4.125 3.7678 4.19743 3.63886 4.32636C3.50993 4.4553 3.4375 4.63016 3.4375 4.8125V9.625C3.4375 17.875 11 19.9375 11 19.9375C11 19.9375 18.5625 17.875 18.5625 9.625Z"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                  </g>
                  <defs>
                      <clipPath>
                          <rect width="22" height="22" fill="white"></rect>
                      </clipPath>
                  </defs>
              </svg>`,
    },
    {
      title: t('nav.lang'),
      link: SettingsPath.language,
      image: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                  <g>
                      <path
                        d="M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M14.4375 11C14.4375 16.5 11 19.25 11 19.25C11 19.25 7.5625 16.5 7.5625 11C7.5625 5.5 11 2.75 11 2.75C11 2.75 14.4375 5.5 14.4375 11Z"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path d="M3.21924 8.25H18.7808" stroke="currentColor" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M3.21924 13.75H18.7808" stroke="currentColor" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round"></path>
                  </g>
                  <defs>
                      <clipPath>
                          <rect width="22" height="22" fill="white"></rect>
                      </clipPath>
                  </defs>
              </svg>`,
    },
    {
      title: t('nav.notif'),
      link: SettingsPath.notifications,
      image: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path
                      d="M8.25 16.5C8.25 17.2293 8.53973 17.9288 9.05546 18.4445C9.57118 18.9603 10.2707 19.25 11 19.25C11.7293 19.25 12.4288 18.9603 12.9445 18.4445C13.4603 17.9288 13.75 17.2293 13.75 16.5"
                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                    <path d="M15.8125 2.0625C17.1401 2.9096 18.2169 4.09647 18.9312 5.5"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M3.06885 5.5C3.78313 4.09647 4.85992 2.9096 6.18752 2.0625"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path
                      d="M4.81227 9.625C4.81227 7.98397 5.46417 6.41016 6.62455 5.24978C7.78493 4.0894 9.35874 3.4375 10.9998 3.4375C12.6408 3.4375 14.2146 4.0894 15.375 5.24978C16.5354 6.41016 17.1873 7.98397 17.1873 9.625C17.1873 12.7033 17.9006 14.4891 18.4677 15.4688C18.528 15.5731 18.5597 15.6914 18.5598 15.8119C18.56 15.9324 18.5284 16.0508 18.4683 16.1552C18.4083 16.2596 18.3218 16.3465 18.2177 16.407C18.1135 16.4675 17.9952 16.4996 17.8748 16.5H4.12477C4.00444 16.4993 3.88641 16.467 3.78247 16.4063C3.67853 16.3457 3.59233 16.2589 3.53248 16.1545C3.47263 16.0501 3.44122 15.9318 3.44141 15.8115C3.44159 15.6911 3.47335 15.573 3.53352 15.4688C4.09985 14.4891 4.81227 12.7024 4.81227 9.625Z"
                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                </g>
                <defs>
                    <clipPath>
                        <rect width="22" height="22" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>`,
    },
  ]

  return (
    <div className="settings__links">
      <h2 className="mb-28 fw-500">{t('nav.title')}</h2>
      <div className="tab-link-group">
        {tabs &&
          tabs.map((el, idx) => (
            <NavLink
              to={`/settings/${el.link}`}
              key={idx}
              className={({ isActive }) =>
                `tab-link btn btn--md radius ${isActive ? 'active-tab' : ''}`
              }
              data-tab-link=""
            >
              <span
                className="tab-link__decor-svg"
                dangerouslySetInnerHTML={{ __html: el.image }}
              ></span>
              {el.title}
            </NavLink>
          ))}
      </div>
      <DownloadAppButtonGroup />
    </div>
  )
}
